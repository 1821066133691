import React from 'react'
import Layout from '../components/layout'

const LinkedinPosts = ({ location }) => {

  const linkedInDetails= [
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7176471461321220096",
      link: "https://www.linkedin.com/posts/kongkona-das-54139b32_honored-to-represent-vantage-circle-on-nb-activity-7176473243699015680-0bPI?utm_source=share&utm_medium=member_desktop"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7184480410905554945",
      link: "https://www.linkedin.com/posts/mahajanpiyush87_employeeengagement-employeemotivation-recognition-activity-7184480411631169536--RGy?utm_source=share&utm_medium=member_desktop"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7171764875197726720",
      link: "https://www.linkedin.com/posts/soumyadeep-deb-6359b7184_internationalwomensday-vantagefitjourney-activity-7171764876288233472-eJkp/?utm_source=share&utm_medium=member_desktop"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7169759496515280896",
      link: "https://www.linkedin.com/posts/gauravnagaon_too-beautiful-thangsning-waterfall-is-activity-7170636650279096320-OD1z/?utm_source=share&utm_medium=member_desktop"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7145461073935056896",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7145461074610364417/?actorCompanyId=3645080"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7144240389196079104",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7144240392325021696/?actorCompanyId=3645080"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7146873587537199104",
      link: "https://www.linkedin.com/posts/gauravnagaon_from-campus-to-corporate-my-new-life-in-activity-7146873588103491584-ZtS3?utm_source=share&utm_medium=member_desktop"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7143331037002018817",
      link: "https://www.linkedin.com/posts/gauravnagaon_my-article-published-today-talks-about-csr-activity-7143457566138785792-E-RP?utm_source=share&utm_medium=member_desktop"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7143169687047106561",
      link: "https://www.linkedin.com/posts/starperformerpriyangshu_rewardsandrecognition-awards-vantagecircle-activity-7143169687722385410-VQTX?utm_source=share&utm_medium=member_desktop"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7133745040606044160",
      link: "https://www.linkedin.com/posts/gauravnagaon_behind-the-brilliance-of-creativity-at-vantage-activity-7135122665152872451-fmHk/?utm_source=share&utm_medium=member_desktop"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7109463729066455040",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7109463730467315713/?actorCompanyId=3645080"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7105987573952462848",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7105987574585831425/?actorCompanyId=3645080"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7103391874542923776",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7103391875541184512/?actorCompanyId=3645080"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7101632746795511808",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7101632747642761216/?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A7101632747642761216%2C7102579731102859265%29&actorCompanyId=3645080"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7095773698233692160",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7095773699030618112/?actorCompanyId=3645080"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7093078628283916289",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7093078629194113024/?actorCompanyId=3645080"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7103404018940411906",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7103404020114894851/?actorCompanyId=3645080"
    },
    {
      iframeSrc: "https://www.linkedin.com/embed/feed/update/urn:li:share:7104811048041353216",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7104811049010290689/?actorCompanyId=3645080"
    }
  ]
  return (
    <Layout location={location}>

      <div className='bgLinkedinHero flex justify-center items-center'>
          <h2 className='text-4xl xl:text-7xl text-purple-lighter w-10/12 text-center lg:max-w-5xl'>What employees think about us?</h2>
      </div>
    <div className='px-5'>
      <p className='text-center mx-auto max-w-4xl my-10 xl:mt-16 text-gray-500'>At Vantage Circle, our employees are not just part of a team; they're the heart and soul of our organization. We believe in celebrating the voices and stories of our talented professionals who make us who we are.</p>
      <p className='text-center mx-auto max-w-4xl mb-10 xl:mb-16 text-gray-500'>Dive into their stories to discover the unique tapestry that makes up Vantage Circle.</p>
    </div>

      {/* <div className='mx-auto'>
          <picture className=''> 
            <source src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/peoplespeak-hero-web.webp" media="(min-width: 1024px)" />
            <source src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/peoplespeak-hero-app.webp" media="(max-width: 1023px)" />
            <img className='mx-auto' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/peoplespeak-hero-web.png" alt="A description of the image." loading = "lazy"  decoding="async" />
          </picture>
      </div> */}
       {/* <h2 className='text-center mx-auto text-3xl xl:text-5xl my-10 xl:my-16'>What people think about us?</h2> */}
       <div className='grid w-full place-content-center my-10 xl:my-16'>
        <div className='grid mx-auto max-w-7xl grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8'>
          {
            linkedInDetails.map(post => {
              return(
                <div className='rounded-xl shadow-lg overflow-x-scroll'>
                  <iframe src={post.iframeSrc} height="700" width="350" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
                  <div className='w-full my-7 place-content-center'><a href={post.link} className='vc-colored-btn-indigo-small w-4/6 mx-auto'>View Post</a></div>
                </div>
              )
            })
          }
          </div>
       </div>
       </Layout>
  )
}

export default LinkedinPosts